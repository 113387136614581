import React, { useState } from "react"
import ReactHtmlParser from 'react-html-parser'

import { useStaticQuery, graphql } from "gatsby"
import { navigate } from "@reach/router"

const Layers = ({ location }) => {
  const [active, setActive] = useState("0")

  const handleClick = (e, l) => {
    e.stopPropagation();
    setActive(l)

    const layerObject = data.allStrapiLayer.nodes.find(layer => layer.order === l)
    navigate(`${location.pathname}#${layerObject.slug}`)
  }

  const data = useStaticQuery(graphql`
    query LayerQuery {
      allStrapiLayer(sort: {fields: order}) {
        nodes {
          id
          title
          slug
          order
          description
        }
      }
    }
  `)

  const layerObject = data.allStrapiLayer.nodes.find(layer => layer.slug === (location.hash || "").replace("#", ""))
  const current = layerObject ? layerObject.order : active
  const mainLayer = parseInt(current[0])

  return (
    <section id="layers">
      <div className="row">
        <div className="columns-2">
          <div className="column" 
            onClick={e => { handleClick(e, "0") }} 
            onKeyDown={e => { if(e.keyCode === 27) handleClick(e, "0") }} 
            tabIndex="0" 
            role="button" 
            aria-label="reset view">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 620 794.17"><g style={{ transform: "translateY(120px)" }}>

              <g id="foundation-layer-idle" 
                className={`clickable ${mainLayer === 1 ? 'inactive' : 'idle'} ${mainLayer >= 2 ? "down" : ""}`} 
                onClick={e => handleClick(e, "1")} role="button" aria-label="Learn more about the Foundation Layer">
                <polygon className="t-u" points="330.14 347.51 166.01 442.89 0.81 347.51 164.94 252.13 330.14 347.51" />
                <polygon className="t-r" points="330.14 347.51 329.98 406.21 165.85 501.59 166.01 442.89 330.14 347.51" />
                <polygon className="t-l" points="166.01 442.89 165.85 501.59 0.65 406.21 0.81 347.51 166.01 442.89" />
                <path className="stroked" d="M784.22,662.07s-34,12.22-57.54,9.26" transform="translate(-382.24 -297.45)" />
                <text transform="translate(413.2 366.77)">Foundation</text>
              </g>

              <g id="design-layer-idle" 
                className={`clickable ${mainLayer === 2 ? 'inactive' : 'idle'} ${mainLayer >= 1 && mainLayer < 2 ? "up" : ""} ${mainLayer >= 3 ? "down" : ""}`} 
                onClick={e => handleClick(e, "2")} role="button" aria-label="Learn more about the Design Layer">
                <polygon className="t-u" points="330.23 241.01 166.1 336.39 0.89 241.01 165.02 145.63 330.23 241.01" />
                <polygon className="t-r" points="330.23 241.01 330.06 299.7 165.93 395.09 166.1 336.39 330.23 241.01" />
                <polygon className="t-l" points="166.1 336.39 165.93 395.09 0.73 299.71 0.89 241.01 166.1 336.39" />
                <path className="stroked" d="M784.22,557.07s-34,12.22-57.54,9.26" transform="translate(-382.24 -297.45)" />
                <text transform="translate(413.2 262.02)">Design process</text>
              </g>

              <g id="design-layer-active" 
                className={`${current === "2" ? 'active' : 'inactive'}`}>
                <g>
                  <polygon className="t-l" points="72.17 343.87 58.07 335.67 57.38 91.12 71.47 99.31 72.17 343.87" />
                  <polygon className="t-r" points="84.08 33.25 109.78 77.19 97.01 84.57 97.7 329.12 72.17 343.87 71.47 99.31 58.7 106.68 84.08 33.25" />
                  <polygon className="t-u" points="58.7 106.68 44.61 98.49 69.98 25.06 84.08 33.25 58.7 106.68" />
                </g>
                <g>
                  <polygon className="r-r" points="261.35 99.31 275.45 91.12 274.76 335.67 260.66 343.87 261.35 99.31" />
                  <polygon className="r-l" points="248.75 33.25 274.12 106.68 261.35 99.31 260.66 343.87 235.12 329.12 235.82 84.57 223.05 77.19 248.75 33.25" />
                  <polygon className="r-u" points="248.75 33.25 262.85 25.06 288.22 98.49 274.12 106.68 248.75 33.25" />
                </g>
                <g>
                  <polygon className="p-r" points="83.44 202.56 97.54 194.37 96.84 438.92 82.75 447.11 83.44 202.56" />
                  <polygon className="p-l" points="70.84 136.5 96.21 209.93 83.44 202.56 82.75 447.11 57.21 432.37 57.9 187.81 45.13 180.44 70.84 136.5" />
                  <polygon className="p-u" points="70.84 136.5 84.94 128.3 110.31 201.74 96.21 209.93 70.84 136.5" />
                </g>
                <g>
                  <polygon className="y-r" points="250.08 447.11 235.98 438.92 235.29 194.37 249.38 202.56 250.08 447.11" />
                  <polygon className="y-u" points="261.99 136.5 287.69 180.44 274.92 187.81 275.62 432.37 250.08 447.11 249.38 202.56 236.62 209.93 261.99 136.5" />
                  <polygon className="y-l" points="236.62 209.93 222.52 201.74 247.89 128.3 261.99 136.5 236.62 209.93" />
                </g>
                <path className="stroked" d="M794.75,557.07s-34,12.22-57.54,9.26" transform="translate(-382.24 -298.05)" />
                <text className="strong" transform="translate(423.2 261.42)">Design process</text>
              </g>

              <g id="tech-layer-idle" 
                className={`${mainLayer !== 3 ? 'idle' : ''} ${mainLayer === 3 ? 'inactive' : ''} ${ mainLayer > 0 && mainLayer < 3 ? "up" : ""} ${ mainLayer > 3 ? "down" : ""}`} 
                onClick={e => handleClick(e, "3")} role="button" aria-label="Learn more about the Tech Layer">
                <polygon className="t-u" points="330.14 130.51 166.01 225.9 0.81 130.51 164.94 35.13 330.14 130.51" />
                <polygon className="t-r" points="330.14 130.51 329.98 189.21 165.85 284.59 166.01 225.9 330.14 130.51" />
                <polygon className="t-l" points="166.01 225.9 165.85 284.59 0.65 189.21 0.81 130.51 166.01 225.9" />
                <path className="stroked" d="M784.22,446.07s-34,12.22-57.54,9.26" transform="translate(-382.24 -297.45)" />
                <text transform="translate(413.2 152.16)">Tech stack</text>
              </g>

              <g id="foundation-layer-active" 
                className={`${mainLayer >= 1 && mainLayer < 2 ? 'active' : 'inactive'}`}>
                  <g>
                    <path className="stroked" d="M783.19,662.07s-34,12.22-57.54,9.26" transform="translate(-382.24 -297.45)" />
                    <text className="strong" transform="translate(413.2 366.77)">Foundation</text>
                  </g>
                <g className={`${current !== "1.1" ? 'idle' : ''}`}
                  onClick={e => handleClick(e, "1.1")} role="button" aria-label="Learn more about the Starting Points and assumptions Layer">
                  <polygon className="r-u" points="239.62 295.21 165.76 338.13 91.42 295.21 165.28 252.29 239.62 295.21" />
                  <polygon className="r-r" points="239.62 295.21 239.45 353.9 165.59 396.82 165.76 338.13 239.62 295.21" />
                  <polygon className="r-l" points="165.76 338.13 165.59 396.82 91.25 353.9 91.42 295.21 165.76 338.13" />
                  <path className="stroked" d="M668.08,563.26s-41.8-24-73.56,5.08" transform="translate(-382.24 -298.05)" />
                  <text transform="translate(294.77 269.89)">
                    <tspan x="0" y="0">Starting points</tspan>
                    <tspan x="0" y="18">and assumptions</tspan>
                  </text>
                </g>
                <g className={`${current !== "1.2" ? 'idle' : ''}`} 
                  onClick={e => handleClick(e, "1.2")} role="button" aria-label="Learn more about the Fundamental rights and values Layer">
                  <polygon className="y-u" points="329.61 347.21 255.76 390.13 181.42 347.21 255.28 304.29 329.61 347.21" />
                  <polygon className="y-r" points="329.61 347.21 329.45 405.9 255.59 448.82 255.76 390.13 329.61 347.21" />
                  <polygon className="y-l" points="255.76 390.13 255.59 448.82 181.26 405.9 181.42 347.21 255.76 390.13" />
                  <path className="stroked" d="M681.36,731.38s6.58,22.8,28.12,28.73" transform="translate(-382.24 -297.45)" />
                  <text transform="translate(336.77, 465.89)">
                    <tspan x="0" y="0">Fundamental rights</tspan>
                    <tspan x="0" y="18">and values</tspan>
                  </text>
                </g>
                <g className={`${current !== "1.4" ? 'idle' : ''}`}
                  onClick={e => handleClick(e, "1.4")}  role="button" aria-label="Learn more about the Socio-economic considerations Layer">
                  <polygon className="t-u" points="149.61 347.21 75.76 390.13 1.42 347.21 75.28 304.29 149.61 347.21" />
                  <polygon className="t-r" points="149.61 347.21 149.45 405.9 75.59 448.82 75.76 390.13 149.61 347.21" />
                  <polygon className="t-l" points="75.76 390.13 75.59 448.82 1.26 405.9 1.42 347.21 75.76 390.13" />
                  <path className="stroked" d="M409.54,731.38s-9.81,30.87,11.16,64.35" transform="translate(-382.24 -298.05)" />
                  <text transform="translate(7.25 514.64)">
                    <tspan x="0" y="0">Socio-economic</tspan>
                    <tspan x="0" y="18">considerations</tspan>
                  </text>
                </g>
                <g className={`${current !== "1.3" ? 'idle' : ''}`}
                  onClick={e => handleClick(e, "1.3")} role="button" aria-label="Learn more about the Governance and oversight Layer">
                  <polygon className="p-u" points="239.61 400.21 165.76 443.13 91.42 400.21 165.28 357.29 239.61 400.21" />
                  <polygon className="p-r" points="239.61 400.21 239.45 458.9 165.59 501.82 165.76 443.13 239.61 400.21" />
                  <polygon className="p-l" points="165.76 443.13 165.59 501.82 91.26 458.9 91.42 400.21 165.76 443.13" />
                  <path className="stroked" d="M623.31,805.62S612.06,791,597.43,784.24" transform="translate(-382.24 -298.05)" />
                  <text transform="translate(201.39 531.52)">
                    <tspan x="0" y="0">Governance</tspan>
                    <tspan x="0" y="18">and oversight</tspan>
                  </text>
                </g>
              </g>

              <g id="tech-layer-active" 
                className={`${mainLayer < 3 ? 'inactive' : ''}`}>
                <g className={`${current === "3" ? '' : 'inactive'}`}>
                  <text className="strong" transform="translate(413.2 40.43)">Tech stack</text>
                </g>
                <g id="contexts-layers-idle" 
                  className={`clickable ${current === "3" ? 'idle' : 'inactive'}`} 
                  onClick={e => handleClick(e, "3.1")} role="button" aria-label="Learn more about the Contexts Layers">
                  <polygon className="t-u" points="330.14 130.51 166.01 225.9 0.81 130.51 164.94 35.13 330.14 130.51" />
                  <polygon className="t-r" points="330.14 130.51 329.98 189.21 165.85 284.59 166.01 225.9 330.14 130.51" />
                  <polygon className="t-l" points="166.01 225.9 165.85 284.59 0.65 189.21 0.81 130.51 166.01 225.9" />
                  <path className="stroked" d="M726.68,460s19.28-23.49,57.54-34.49" transform="translate(-382.24 -297.45)" />
                  <text transform="translate(413.2 132.5)">Context layers</text>
                </g>
                <g id="tech-layers-idle" 
                  className={`clickable ${current === "3" ? 'idle' : 'inactive'}`} 
                  onClick={e => handleClick(e, "3.2")} role="button" aria-label="Learn more about the Tech Layers">
                  <polygon className="t-u dotted" points="273.68 129.63 165.63 192.42 56.87 129.63 164.92 66.83 273.68 129.63" />
                  <polygon className="t-r dotted" points="273.68 129.63 273.66 190.68 165.61 253.47 165.63 192.42 273.68 129.63" />
                  <polygon className="t-l dotted" points="165.63 192.42 165.61 253.47 56.85 190.68 56.87 129.63 165.63 192.42" />
                  <path className="stroked" d="M784.63,376.68s-66.15-23.7-185.11,7.66" transform="translate(-382.24 -297.45)" />
                  <text transform="translate(413.2 86.5)">
                    Tech layers
                  </text>
                </g>
              </g>

              <g id="context-layers-active" 
                className={`${current.includes("3.1") ? 'active' : 'inactive'}`}>
                <g className={`${current !== "3.1.4" ? 'idle' : ''}`}
                  onClick={e => handleClick(e, "3.1.4")} role="button" aria-label="Learn more about the Data and algorithms Layer">
                  <polygon className="r-l" points="278.13 174.6 278.09 189.27 164.61 123.76 164.66 109.08 278.13 174.6" />
                  <polygon className="r-r" points="164.66 109.08 164.61 123.76 51.88 189.27 51.92 174.6 164.66 109.08" />
                  <path className="r-u" d="M546.72,377.16,712.1,472.65,547.79,568.14,382.4,472.65Zm.91,161,112.73-65.52L546.89,407.14,434.15,472.65l113.48,65.52" transform="translate(-382.24 -298.05)" />
                  <polygon className="r-r" points="329.87 174.6 329.83 189.27 165.51 284.76 165.56 270.09 329.87 174.6" />
                  <polygon className="r-l" points="165.56 270.09 165.51 284.76 0.13 189.27 0.17 174.6 165.56 270.09" />
                  <path className="stroked" d="M784.22,467.05c.69-.25-32,12.47-57.54,9.25" transform="translate(-382.24 -297.45)" />
                  <text transform="translate(413.2 174.36)">Data and algorithms</text>
                </g>
                <g className={`${current !== "3.1.3" ? 'idle' : ''}`}
                  onClick={e => handleClick(e, "3.1.3")} role="button" aria-label="Learn more about the Protocols and standards Layer">
                  <polygon className="p-l" points="278.13 159.59 278.09 174.27 164.61 108.75 164.66 94.08 278.13 159.59" />
                  <polygon className="p-r" points="164.66 94.08 164.61 108.75 51.87 174.27 51.91 159.6 164.66 94.08" />
                  <path className="p-u" d="M546.72,362.16,712.1,457.65,547.79,553.14,382.4,457.65Zm.9,161,112.74-65.52L546.89,392.13,434.15,457.65l113.47,65.52" transform="translate(-382.24 -298.05)" />
                  <polygon className="p-r" points="329.87 159.59 329.83 174.27 165.51 269.76 165.56 255.08 329.87 159.59" />
                  <polygon className="p-l" points="165.56 255.08 165.51 269.76 0.13 174.27 0.17 159.6 165.56 255.08" />
                  <path className="stroked" d="M784.22,452.05c.69-.25-32,12.47-57.54,9.25" transform="translate(-382.24 -297.45)" />
                  <text transform="translate(413.2 159.61)">Protocols and standards</text>
                </g>
                <g className={`${current !== "3.1.2" ? 'idle' : ''}`}
                  onClick={e => handleClick(e, "3.1.2")} role="button" aria-label="Learn more about the Security Layer">
                  <polygon className="t-l" points="278.13 144.59 278.09 159.26 164.61 93.75 164.66 79.08 278.13 144.59" />
                  <polygon className="t-r" points="164.66 79.08 164.61 93.75 51.87 159.27 51.91 144.6 164.66 79.08" />
                  <path className="t-u" d="M546.72,347.16,712.1,442.64,547.79,538.13,382.4,442.65Zm.9,161,112.74-65.52L546.89,377.13,434.15,442.65l113.47,65.51" transform="translate(-382.24 -298.05)" />
                  <polygon className="t-r" points="329.87 144.59 329.83 159.26 165.51 254.75 165.56 240.08 329.87 144.59" />
                  <polygon className="t-l" points="165.56 240.08 165.51 254.75 0.13 159.27 0.17 144.59 165.56 240.08" />
                  <path className="stroked" d="M784.22,437.05c.69-.25-32,12.47-57.54,9.25" transform="translate(-382.24 -297.45)" />
                  <text transform="translate(413.2 145.75)">Security</text>
                </g>
                <g className={`${current !== "3.1.1" ? 'idle' : ''}`}
                  onClick={e => handleClick(e, "3.1.1")} role="button" aria-label="Learn more about the Service Layer">
                  <polygon className="y-u" points="278.13 129.6 278.09 144.27 164.61 78.76 164.66 64.08 278.13 129.6" />
                  <polygon className="y-u" points="164.66 64.08 164.61 78.76 51.88 144.27 51.92 129.6 164.66 64.08" />
                  <path className="y-u" d="M546.72,332.16,712.1,427.65,547.79,523.14,382.4,427.65Zm.91,161,112.73-65.52L546.89,362.14,434.15,427.65l113.48,65.52" transform="translate(-382.24 -298.05)" />
                  <polygon className="y-r" points="329.87 129.6 329.83 144.27 165.51 239.76 165.56 225.09 329.87 129.6" />
                  <polygon className="y-l" points="165.56 225.09 165.51 239.76 0.13 144.27 0.17 129.6 165.56 225.09" />
                  <path className="stroked" d="M784.22,422.05c.69-.25-32,12.47-57.54,9.25" transform="translate(-382.24 -297.45)" />
                  <text transform="translate(413.2 129.26)">Service</text>
                </g>
                <g>
                  <text className="strong" transform="translate(413.2 99.93)">
                    <tspan x="0" y="0">Tech stack:</tspan>
                    <tspan x="0" y="18">context layers</tspan>
                  </text>
                </g>
              </g>

              <g id="tech-layers-active" 
                className={`${current.includes("3.2") ? 'active' : 'inactive'}`}>
                <g className={`${current !== "3.2.5" ? 'idle' : ''}`}
                  onClick={e => handleClick(e, "3.2.5")} role="button" aria-label="Learn more about the Infrastructure Layer">
                  <polygon className="r-u" points="273.24 184.44 165.18 247.24 56.42 184.44 164.47 121.65 273.24 184.44" />
                  <polygon className="r-r" points="273.24 184.44 273.21 192.49 165.16 255.29 165.18 247.24 273.24 184.44" />
                  <polygon className="r-l" points="165.18 247.24 165.16 255.29 56.4 192.49 56.42 184.44 165.18 247.24" />
                  <path className="stroked" d="M717.56,477.07s-34,12.22-57.54,9.25" transform="translate(-382.24 -297.45)" />
                  <text transform="translate(346.01 181.52)">Infrastructure</text>
                </g>
                <g className={`${current !== "3.2.4" ? 'idle' : ''}`}
                  onClick={e => handleClick(e, "3.2.4")} role="button" aria-label="Learn more about the Equipment Layer">
                  <polygon className="p-u" points="273.24 169.69 165.18 232.49 56.42 169.69 164.47 106.9 273.24 169.69" />
                  <polygon className="p-r" points="273.24 169.69 273.21 177.74 165.16 240.54 165.18 232.49 273.24 169.69" />
                  <polygon className="p-l" points="165.18 232.49 165.16 240.54 56.4 177.75 56.42 169.69 165.18 232.49" />
                  <path className="stroked" d="M717.56,463.07s-34,12.22-57.54,9.25" transform="translate(-382.24 -297.45)" />
                  <text transform="translate(346.01 167.77)">Equipment</text>
                </g>
                <g className={`${current !== "3.2.3" ? 'idle' : ''}`}
                  onClick={e => handleClick(e, "3.2.3")} role="button" aria-label="Learn more about the Firmware and drivers Layer">
                  <polygon className="t-u" points="273.24 154.94 165.18 217.74 56.42 154.95 164.47 92.15 273.24 154.94" />
                  <polygon className="t-r" points="273.24 154.94 273.21 163 165.16 225.79 165.18 217.74 273.24 154.94" />
                  <polygon className="t-l" points="165.18 217.74 165.16 225.79 56.4 163 56.42 154.95 165.18 217.74" />
                  <path className="stroked" d="M717.56,448.07s-34,12.22-57.54,9.25" transform="translate(-382.24 -298.05)" />
                  <text transform="translate(346.01 152.41)">Firmware and drivers</text>
                </g>
                <g className={`${current !== "3.2.2" ? 'idle' : ''}`}
                  onClick={e => handleClick(e, "3.2.2")} role="button" aria-label="Learn more about the Operating system Layer">
                  <polygon className="y-u" points="273.24 140.19 165.18 202.98 56.42 140.19 164.47 77.4 273.24 140.19" />
                  <polygon className="y-r" points="273.24 140.19 273.21 148.24 165.16 211.03 165.18 202.98 273.24 140.19" />
                  <polygon className="y-l" points="165.18 202.98 165.16 211.03 56.4 148.24 56.42 140.19 165.18 202.98" />
                  <path className="stroked" d="M717.56,433.07s-34,12.22-57.54,9.25" transform="translate(-382.24 -298.05)" />
                  <text transform="translate(346.01 138.56)">Operating system</text>
                </g>
                <g className={`${current !== "3.2.1" ? 'idle' : ''}`}
                  onClick={e => handleClick(e, "3.2.1")} role="button" aria-label="Learn more about the Application Layer">
                  <polygon className="w-u" points="92.83 128.67 74.69 139.21 56.45 128.67 74.58 118.13 92.83 128.67" />
                  <polygon className="w-r" points="92.83 128.67 92.81 131.82 74.67 142.36 74.69 139.21 92.83 128.67" />
                  <polygon className="w-l" points="74.69 139.21 74.67 142.36 56.42 131.82 56.45 128.67 74.69 139.21" />
                  <polygon className="w-u" points="124.27 110.61 106.13 121.14 87.88 110.61 106.02 100.07 124.27 110.61" />
                  <polygon className="w-r" points="124.27 110.61 124.24 113.76 106.11 124.3 106.13 121.14 124.27 110.61" />
                  <polygon className="w-l" points="106.13 121.14 106.11 124.3 87.86 113.76 87.88 110.61 106.13 121.14" />
                  <polygon className="w-u" points="153.51 93.54 135.38 104.08 117.13 93.55 135.27 83.01 153.51 93.54" />
                  <polygon className="w-r" points="153.51 93.54 153.49 96.69 135.36 107.23 135.38 104.08 153.51 93.54" />
                  <polygon className="w-l" points="135.38 104.08 135.36 107.23 117.11 96.7 117.13 93.55 135.38 104.08" />
                  <polygon className="w-u" points="182.26 76.44 164.13 86.98 145.88 76.44 164.02 65.9 182.26 76.44" />
                  <polygon className="w-r" points="182.26 76.44 182.24 79.59 164.11 90.13 164.13 86.98 182.26 76.44" />
                  <polygon className="w-l" points="164.13 86.98 164.11 90.13 145.86 79.59 145.88 76.44 164.13 86.98" />
                  <polygon className="w-u" points="122.27 145.77 104.13 156.31 85.88 145.78 104.02 135.24 122.27 145.77" />
                  <polygon className="w-r" points="122.27 145.77 122.24 148.93 104.11 159.46 104.13 156.31 122.27 145.77" />
                  <polygon className="w-l" points="104.13 156.31 104.11 159.46 85.86 148.93 85.88 145.78 104.13 156.31" />
                  <polygon className="w-u" points="153.7 127.71 135.57 138.25 117.32 127.72 135.46 117.18 153.7 127.71" />
                  <polygon className="w-r" points="153.7 127.71 153.68 130.86 135.54 141.4 135.57 138.25 153.7 127.71" />
                  <polygon className="w-l" points="135.57 138.25 135.54 141.4 117.3 130.87 117.32 127.72 135.57 138.25" />
                  <polygon className="r-u" points="182.95 110.65 164.82 121.19 146.57 110.65 164.71 100.11 182.95 110.65" />
                  <polygon className="r-r" points="182.95 110.65 182.93 113.8 164.79 124.34 164.82 121.19 182.95 110.65" />
                  <polygon className="r-l" points="164.82 121.19 164.79 124.34 146.55 113.8 146.57 110.65 164.82 121.19" />
                  <polygon className="w-u" points="211.7 93.54 193.57 104.08 175.32 93.55 193.46 83.01 211.7 93.54" />
                  <polygon className="w-r" points="211.7 93.54 211.68 96.69 193.54 107.23 193.57 104.08 211.7 93.54" />
                  <polygon className="w-l" points="193.57 104.08 193.54 107.23 175.3 96.7 175.32 93.55 193.57 104.08" />
                  <polygon className="w-u" points="152.2 162.88 134.07 173.42 115.82 162.88 133.96 152.34 152.2 162.88" />
                  <polygon className="w-r" points="152.2 162.88 152.18 166.03 134.04 176.57 134.07 173.42 152.2 162.88" />
                  <polygon className="w-l" points="134.07 173.42 134.04 176.57 115.8 166.03 115.82 162.88 134.07 173.42" />
                  <polygon className="w-u" points="183.64 144.82 165.5 155.36 147.26 144.82 165.39 134.28 183.64 144.82" />
                  <polygon className="w-r" points="183.64 144.82 183.62 147.97 165.48 158.51 165.5 155.36 183.64 144.82" />
                  <polygon className="w-l" points="165.5 155.36 165.48 158.51 147.24 147.97 147.26 144.82 165.5 155.36" />
                  <polygon className="w-u" points="212.89 127.75 194.75 138.29 176.51 127.76 194.64 117.22 212.89 127.75" />
                  <polygon className="w-r" points="212.89 127.75 212.87 130.91 194.73 141.44 194.75 138.29 212.89 127.75" />
                  <polygon className="w-l" points="194.75 138.29 194.73 141.44 176.49 130.91 176.51 127.76 194.75 138.29" />
                  <polygon className="w-u" points="241.64 110.65 223.5 121.19 205.26 110.65 223.39 100.11 241.64 110.65" />
                  <polygon className="w-r" points="241.64 110.65 241.62 113.8 223.48 124.34 223.5 121.19 241.64 110.65" />
                  <polygon className="w-l" points="223.5 121.19 223.48 124.34 205.24 113.8 205.26 110.65 223.5 121.19" />
                  <polygon className="w-u" points="183.8 180.9 165.66 191.44 147.41 180.9 165.55 170.36 183.8 180.9" />
                  <polygon className="w-r" points="183.8 180.9 183.77 184.05 165.64 194.59 165.66 191.44 183.8 180.9" />
                  <polygon className="w-l" points="165.66 191.44 165.64 194.59 147.39 184.06 147.41 180.9 165.66 191.44" />
                  <polygon className="w-u" points="215.24 162.84 197.1 173.38 178.85 162.84 196.99 152.3 215.24 162.84" />
                  <polygon className="w-r" points="215.24 162.84 215.21 165.99 197.08 176.53 197.1 173.38 215.24 162.84" />
                  <polygon className="w-l" points="197.1 173.38 197.08 176.53 178.83 165.99 178.85 162.84 197.1 173.38" />
                  <polygon className="w-u" points="244.49 145.78 226.35 156.31 208.1 145.78 226.24 135.24 244.49 145.78" />
                  <polygon className="w-r" points="244.49 145.78 244.46 148.93 226.33 159.47 226.35 156.31 244.49 145.78" />
                  <polygon className="w-l" points="226.35 156.31 226.33 159.47 208.08 148.93 208.1 145.78 226.35 156.31" />
                  <polygon className="w-u" points="273.24 128.67 255.1 139.21 236.85 128.67 254.99 118.13 273.24 128.67" />
                  <polygon className="w-r" points="273.24 128.67 273.21 131.82 255.08 142.36 255.1 139.21 273.24 128.67" />
                  <polygon className="w-l" points="255.1 139.21 255.08 142.36 236.83 131.82 236.85 128.67 255.1 139.21" />
                  <path className="stroked" d="M717.56,418.07s-34,12.22-57.54,9.25" transform="translate(-382.24 -298.05)" />
                  <text transform="translate(346.01 122.81)">Application layer</text>
                </g>
                <g>
                  <text className="strong" transform="translate(346.01 80.03)">
                    <tspan x="0" y="0">Tech stack:</tspan>
                    <tspan x="0" y="18">tech layers</tspan>
                  </text>
                </g>
              </g>

              <g id="citizen-perspective-idle" 
                className={`clickable ${current === "4" ? 'inactive' : 'idle'} ${mainLayer > 0 && mainLayer < 4 ? 'up' : ''}`}
                onClick={e => handleClick(e, "4")} role="button" aria-label="Learn more about the Citizen perspective"
                >
                <g id="shadow">
                  <path className="r-l" d="M560.07,330.7a4,4,0,0,0-1.33-.5,4.78,4.78,0,0,0-1.08-.09,7.68,7.68,0,0,0-.93.09,7.25,7.25,0,0,0-.86.22,8.14,8.14,0,0,0-.86.31,8.64,8.64,0,0,0-.87.42l-.27.15h0l-23.1,13.34,10.57,6.14,23.1-13.33.27-.15c.3-.16.59-.3.87-.43s.58-.22.86-.31a6.87,6.87,0,0,1,.87-.21,5.88,5.88,0,0,1,.92-.1,6.21,6.21,0,0,1,1.08.09,4.26,4.26,0,0,1,1.33.5Z" transform="translate(-382.36 -297.45)" />
                  <path className="r-l" d="M529.13,345.77a15.1,15.1,0,0,0-1.14,1,18.52,18.52,0,0,0-1.89,2.14,19.05,19.05,0,0,0-1.25,1.83,19.9,19.9,0,0,0-1.37,2.68c-.22.51-.41,1-.58,1.54s-.27.85-.37,1.27-.17.76-.24,1.13-.11.72-.14,1.07a9.2,9.2,0,0,0-.06,1v.22h0l.15,53.71,10.57,6.15-.15-53.72h0v-.21a9.24,9.24,0,0,1,.06-1.05c0-.35.08-.71.14-1.06s.15-.76.24-1.13.23-.85.37-1.28.36-1,.58-1.54a21.7,21.7,0,0,1,1.37-2.67,18,18,0,0,1,1.25-1.84,19.52,19.52,0,0,1,1.89-2.14c.37-.35.75-.69,1.14-1s.66-.5,1-.72.44-.28.65-.41l-10.57-6.14-.65.4C529.79,345.27,529.46,345.51,529.13,345.77Z" transform="translate(-382.36 -297.45)" />
                  <path className="r-l" d="M552.08,300.24a7,7,0,0,0-2.12-.79,8,8,0,0,0-1.71-.14,9.64,9.64,0,0,0-1.48.15,10.66,10.66,0,0,0-1.38.34,11.91,11.91,0,0,0-1.36.5c-.45.19-.92.42-1.39.67l-.42.24c-.35.2-.69.41-1,.64-.53.35-1.06.74-1.57,1.15s-1.23,1-1.82,1.6a29,29,0,0,0-3,3.4,32.62,32.62,0,0,0-2,2.92,32.27,32.27,0,0,0-2.18,4.26c-.35.81-.66,1.63-.93,2.45s-.41,1.35-.58,2-.28,1.2-.38,1.8-.17,1.13-.23,1.7-.08,1.11-.09,1.66v.33c0,.46,0,.9.05,1.32a14.71,14.71,0,0,0,.23,1.71,11.11,11.11,0,0,0,.53,1.85,7.62,7.62,0,0,0,3.23,4L543,340.18a7.61,7.61,0,0,1-3.23-4,12.28,12.28,0,0,1-.53-1.86c-.1-.54-.18-1.11-.23-1.7,0-.43,0-.87,0-1.32V331c0-.55,0-1.1.09-1.66s.14-1.13.23-1.7.23-1.19.38-1.79.36-1.36.59-2,.57-1.64.92-2.45a33.53,33.53,0,0,1,2.18-4.26,32.62,32.62,0,0,1,2-2.92,29.83,29.83,0,0,1,3-3.4c.59-.57,1.2-1.1,1.82-1.6s1-.79,1.58-1.15c.34-.22.68-.44,1-.64l.43-.24a14.69,14.69,0,0,1,1.39-.67,14,14,0,0,1,1.35-.5,10.8,10.8,0,0,1,1.39-.34,9.52,9.52,0,0,1,1.47-.15,8.58,8.58,0,0,1,1.71.14,6.69,6.69,0,0,1,2.12.8Z" transform="translate(-382.36 -297.45)" />
                </g>
                <g id="highlight">
                  <path className="r-u" d="M552.79,307.35c7.66-4.42,13.88-.87,13.91,7.93s-6.16,19.51-13.82,23.93-13.89.88-13.91-7.92S545.13,311.78,552.79,307.35Z" transform="translate(-382.36 -297.45)" />
                  <path className="r-u" d="M564.44,337.45c4.81-2.78,8.73-.55,8.75,5l.15,53.72c-.14.42-2.82,8.35-18.88,18.24l-1.58.94c-14.09,8.14-19.77,4.41-20.07,4.22l-.15-53.72c0-5.53,3.87-12.27,8.68-15.05Z" transform="translate(-382.36 -297.45)" />
                </g>
                <path className="stroked" d="M784.22,356.07S722,371.75,586.68,365.33" transform="translate(-382.36 -297.45)" />
                <text className="" transform="translate(413.07 62.16)">Citizen perspective</text>
              </g>

              <g id="citizen-perspective-active" className={`${current === "4" ? 'active' : 'inactive'}`}>
                <g id="shadow-2" data-name="shadow">
                  <path className="r-l" d="M560.05,330.63a4.69,4.69,0,0,0-1.34-.5,5.34,5.34,0,0,0-1.07-.08,5,5,0,0,0-.93.09,6.87,6.87,0,0,0-.87.21,7.35,7.35,0,0,0-.85.32c-.29.12-.58.26-.88.42l-.26.15,10.57,6.14.26-.15c.3-.16.59-.3.88-.42a8,8,0,0,1,.85-.31,6.16,6.16,0,0,1,.87-.22,7.68,7.68,0,0,1,.93-.09,4.69,4.69,0,0,1,1.07.09,4.16,4.16,0,0,1,1.34.5Z" transform="translate(-382.36 -297.45)" />
                  <path className="r-l" d="M530.75,344.57h0l-.65.4c-.33.23-.66.47-1,.73a15.1,15.1,0,0,0-1.14,1,19.4,19.4,0,0,0-1.89,2.13,20.53,20.53,0,0,0-1.25,1.84,22.65,22.65,0,0,0-1.38,2.68c-.21.51-.41,1-.57,1.54s-.27.85-.37,1.28a11.38,11.38,0,0,0-.24,1.13c-.06.36-.11.71-.14,1.06a9.24,9.24,0,0,0-.06,1.05v.21l.15,53.72,10.57,6.14-.15-53.72v-.21a9.2,9.2,0,0,1,.06-1c0-.35.08-.71.14-1.07s.15-.75.24-1.13.23-.85.37-1.27.36-1,.58-1.54a21.13,21.13,0,0,1,1.37-2.68,22,22,0,0,1,1.25-1.84,19.52,19.52,0,0,1,1.89-2.14c.37-.35.75-.69,1.14-1s.66-.5,1-.72.43-.28.64-.4h0l23.1-13.34-10.57-6.14Z" transform="translate(-382.36 -297.45)" />
                  <path className="r-l" d="M552.06,300.17a7,7,0,0,0-2.12-.79,8.71,8.71,0,0,0-1.71-.14,9.64,9.64,0,0,0-1.48.15,10.66,10.66,0,0,0-1.38.34,14.17,14.17,0,0,0-1.36.5c-.45.19-.92.42-1.39.67l-.42.24c-.35.2-.7.42-1,.64-.53.36-1.06.74-1.58,1.15s-1.22,1-1.81,1.6a29,29,0,0,0-3,3.4,32.62,32.62,0,0,0-2,2.92,33.53,33.53,0,0,0-2.18,4.26c-.35.81-.66,1.63-.93,2.45s-.41,1.35-.58,2-.28,1.21-.38,1.8-.17,1.14-.23,1.7-.08,1.12-.09,1.66c0,.12,0,.23,0,.34,0,.46,0,.9,0,1.32a14.54,14.54,0,0,0,.23,1.7,11.23,11.23,0,0,0,.53,1.86,7.6,7.6,0,0,0,3.23,4L543,340.11a7.56,7.56,0,0,1-3.23-4,12.15,12.15,0,0,1-.53-1.85,17.24,17.24,0,0,1-.23-1.71c0-.42,0-.86,0-1.31v-.34c0-.55,0-1.1.09-1.66s.13-1.13.23-1.7.23-1.2.38-1.8.36-1.35.59-2,.57-1.64.92-2.45a33.53,33.53,0,0,1,2.18-4.26,32.62,32.62,0,0,1,2-2.92,30.71,30.71,0,0,1,3-3.4c.59-.56,1.19-1.1,1.82-1.59a17.16,17.16,0,0,1,1.58-1.15c.34-.23.68-.44,1-.65l.42-.23c.47-.26.94-.48,1.39-.68s.92-.36,1.36-.5a12.22,12.22,0,0,1,1.39-.34,9.36,9.36,0,0,1,1.47-.14,7.41,7.41,0,0,1,1.71.14,6.77,6.77,0,0,1,2.12.79Z" transform="translate(-382.36 -297.45)" />
                </g>
                <g id="highlight-2" data-name="highlight">
                  <path className="r-u" d="M552.77,307.28c7.65-4.42,13.88-.87,13.91,7.94s-6.16,19.51-13.82,23.93S539,340,539,331.23,545.11,311.71,552.77,307.28Z" transform="translate(-382.36 -297.45)" />
                  <path className="r-u" d="M564.42,337.38c4.81-2.78,8.72-.54,8.74,5l.15,53.71c-.14.42-2.81,8.36-18.87,18.24-.54.33-1.07.65-1.59.95-14.08,8.13-19.76,4.4-20.06,4.21l-.15-53.72c0-5.52,3.87-12.27,8.68-15Z" transform="translate(-382.36 -297.45)" />
                </g>
                <path className="stroked" d="M784.22,356.07S722,371.75,586.68,365.33" transform="translate(-382.36 -297.45)" />
                <text className="strong" transform="translate(413.07 62.16)">Citizen perspective</text>
              </g>

            </g></svg>
          </div>
          <div className="column">
            { data.allStrapiLayer.nodes.filter(layer => layer.order === current).map(layer => (
              <div key={layer.id}>
                <h2>{layer.title}</h2>
                <div className="text">{ ReactHtmlParser(layer.description) }</div>
              </div>
            )) }
          </div>
        </div>
      </div>
    </section>
  )
}

/* <div className="column" style={{ width: "20%" }}>
<ul id="layer-list">
  { data.allStrapiLayer.nodes.map(layer => 
    <li key={layer.id} className={`depth-${layer.order.length} ${layer.order === active ? "current" : ""}`} onClick={e => handleClick(e, layer.order)}>
      {layer.title}
    </li>
  )}
</ul>
</div> */

export default Layers