import React from "react"

import Layout from "../parts/layout"
import Seo from "../parts/seo"

import Layers from "../parts/layers.js"

const LayersPage = (props) => {
  return (
    <Layout id="layers-page">
      <Seo title="Layers" />
      <Layers id="stack" location={props.location} />
    </Layout>
  )
}

export default LayersPage